import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ReconBanner from "../images/recon-banner.png"
import TafheemBanner from "../images/Tafheem_assets/tafheem-casestudy-banner.png"

// CaseStudy Images
import Typography from "../images/Tafheem_assets/typography.png"
import Colors from "../images/Tafheem_assets/colors.png"
import Icons from "../images/Tafheem_assets/icons.png"
import Categories from "../images/Tafheem_assets/categories.png"
import Lisiting from "../images/Tafheem_assets/lisiting.png"
import Search from "../images/Tafheem_assets/search.png"
import Downloads from "../images/Tafheem_assets/downloads.png"
import Bookmark from "../images/Tafheem_assets/bookmark.png"
import LightMode from "../images/Tafheem_assets/light-mode.png"
import Prototyping from "../images/Tafheem_assets/prototyping.png"
import Thanks from "../images/Tafheem_assets/thanks.png"

// CSS
import "../components/layout.css"
import Banner from "../components/banner"
import Tag from "../components/tag"
import ScrollToTop from "../components/scrollToTop"

const tafheem_us_sunnah = () => (
  <Layout>
    <SEO title="Tafheem Us Sunnah" />
    <div>
      <div className="top-banner">
        <img
          className="w-sm-100 w-100"
          src={TafheemBanner}
          alt={"TafheemBanner"}
        />
      </div>
      <div className="container">
        <div className="d-flex justify-content-evenly flex-sm-col py-8 px-sm-2">
          <div className="pr-3 flex-basis-7">
            <div className="portfolio-detail-intro-head pb-2">
              OVERVIEW & BACKGROUND
            </div>
            <div className="portfolio-detail-intro-p">
              Tafheem us sunnah wanted to redesign their overall mobile app both
              for IOS and Android.
            </div>
            <div className="portfolio-detail-intro-p mt-2">
              The goal was to develop proper style guide 🎨 by choosing the
              right typography, colors and icons that aligns with the brand
              values and principles.
            </div>
          </div>
          <div className="flex-basis-3 mt-sm-4">
            <div className="portfolio-detail-intro-head pb-2">ROLE & TIME</div>
            <div className="portfolio-detail-intro-head pb-1 border-bottom color-dark-blue">
              UI/UX Designer
            </div>
            <div className="portfolio-detail-intro-p border-bottom pb-1 pt-1">
              Visual Design, Style Guide, UI Kit
            </div>
            <div className="portfolio-detail-intro-p pb-1 pt-1">May 2021</div>
          </div>
        </div>
      </div>
      <div>
        <img className="w-sm-100 w-100" src={Typography} alt={"Typography"} />
        <img className="w-sm-100 w-100" src={Colors} alt={"Colors"} />
        <img className="w-sm-100 w-100" src={Icons} alt={"Icons"} />
        <img className="w-sm-100 w-100" src={Categories} alt={"Categories"} />
        <img className="w-sm-100 w-100" src={Lisiting} alt={"Lisiting"} />
        <img className="w-sm-100 w-100" src={Search} alt={"Search"} />
        <img className="w-sm-100 w-100" src={Downloads} alt={"Downloads"} />
        <img className="w-sm-100 w-100" src={Bookmark} alt={"Bookmark"} />
        <img className="w-sm-100 w-100" src={LightMode} alt={"LightMode"} />
        <img className="w-sm-100 w-100" src={Prototyping} alt={"Prototyping"} />
        <img className="w-sm-100 w-100" src={Thanks} alt={"Thanks"} />
      </div>
      <div id="work" className="container px-2 bt-light">
        <h2 className="header-h2 text-center mt-3">Checkout more projects</h2>
        <div>
          <div className="banner-container">
            <Banner
              img={ReconBanner}
              alt="recon-banner"
              color="blue"
              route="/recon_design_system"
            >
              <h1>Recon Design System</h1>
              <p>A Fintech Analytical Dashboard</p>
              <div className="tags-container">
                <Tag name="Design System" />
                <Tag name="IA" />
                <Tag name="Style Guide" />
                <Tag name="Fintech" />
              </div>
            </Banner>
          </div>
          <div className="banner-container">
            <Banner alt="recon-banner" color="green" route="/seamoss_ecommerce">
              <h1>Sea Moss Transformation</h1>
              <p>Redesining & Improving UX & CR</p>
              <div className="tags-container">
                <Tag name="UI/UX Design" />
                <Tag name="Style Guide" />
                <Tag name="UI Kit" />
                <Tag name="E-Commerce" />
              </div>
            </Banner>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop />
  </Layout>
)

export default tafheem_us_sunnah
